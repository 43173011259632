import * as Types from './type';
import transportOptionApi from '../../apis/api/transportOption';
import subPrecinctApi from '../../apis/api/subPrecinct';
import { actSetSelectedHotspot } from '../explore-modal/action';
import modal from '../../apis/api/modal';

const actSetPage = (data) => ({
  type: Types.SET_PAGE,
  data,
});

const actSetIsShowImmerse = (data) => ({
  type: Types.SET_IS_SHOW_IMMERSE,
  data,
});

const actSetIsExpandNav = (data) => ({
  type: Types.SET_IS_EXPAND_NAV,
  data,
});

const actSetIsShowHotspotModal = (data) => ({
  type: Types.SET_IS_SHOW_HOTSPOT_MODAL,
  data
});

const actSetIsShowGalleryModal = (data) => ({
  type: Types.SET_IS_SHOW_HOTPOST_GALLERY_MODAL,
  data
});

const actSetIsShowFuture = (data) => ({
  type: Types.SET_IS_SHOW_FUTURE,
  data
});

const actSetActivePatourId = (data) => ({
  type: Types.SET_ACTIVE_PANTOUR_ID,
  data,
});

const actSetIsShowExploreModal = (data) => ({
  type: Types.SET_IS_SHOW_EXPLORE_MODAL,
  data,
});

const actSetIsTransparent = (data) => ({
  type: Types.SET_IS_TRANSPARENT,
  data,
});

const actSetActiveGalleryId = (data) => ({
  type: Types.SET_ACTIVE_GALLERY_ID,
  data,
});

const actSetActiveAreaId = (data) => ({
  type: Types.SET_ACTIVE_AREA_ID,
  data,
});

const actSetIsShowSubPrecinctDetail = (data) => ({
  type: Types.SET_IS_SHOW_SUB_PRECINCT_DETAIL,
  data,
});

const actActiveDistrictYear = (data) => ({
  type: Types.SET_ACTIVE_DISTRICT_YEAR,
  data,
});

const actSetIsShowLoginModal = (data) => ({
  type: Types.SET_IS_SHOW_LOGIN_MODAL,
  data,
});

const actSetTransportOptions = (data) => ({
  type: Types.SET_TRANSPORT_OPTIONS,
  data,
});

const actSetTransportOptionDistricts = (data) => ({
  type: Types.SET_TRANSPORT_OPTION_DISTRICTS,
  data,
});

const actSetSubPrecincts = (data) => ({
  type: Types.SET_SUB_PRECINCTS,
  data,
});

const actSetActiveTransportOption = (data) => ({
  type: Types.SET_ACTIVE_TRANSPORT_OPTION,
  data,
});

const actSetActiveTransportOptionDistricts = (data) => ({
  type: Types.SET_ACTIVE_TRANSPORT_OPTION_DISTRICTS,
  data,
});

const actSetIsShowEndGuideTenantSession = (data) => ({
  type: Types.SET_IS_SHOW_END_GUIDE_TENANT_SESSION,
  data,
})

const actSetActiveEndGuideTenantSessionId = (data) => ({
  type: Types.SET_ACTIVE_END_GUIDE_TENANT_SESSION_ID,
  data,
})

const actSetIsShowGuidePopup = (data) => ({
  type: Types.SET_IS_SHOW_GUIDE_POPUP,
  data,
})

const acetSetIsLoadedIntroVideo = (data) => ({
  type: Types.SET_IS_LOADED_INTRO_VIDEO,
  data,
})

const actSetListVirtualModals = (data) => ({
  type: Types.SET_LIST_VIRTUAL_MODAL,
  data,
})

const actSetIsShowSidebar = (data) => ({
  type: Types.SET_IS_SHOW_SIDEBAR,
  data,
})

const actSetActiveCategory = (data) => ({
  type: Types.SET_ACTIVE_CATEGORY,
  data,
})

const actSetIsShowCategoryDetail = (data) => ({
  type: Types.SET_IS_SHOW_CATEGORY_DETAIL,
  data,
})

const actSetIsCollapsedCategoryDetail = (data) => ({
  type: Types.SET_IS_COLLAPSED_CATEGORY_DETAIL,
  data,
})

const actSetIsShowVirtualTour = (data) => ({
  type: Types.SET_IS_SHOW_VIRTUAL_TOUR,
  data,
})

const actSetIsShowIntroFutureBtn = (data) => ({
  type: Types.SET_IS_SHOW_INTRO_FUTURE_BTN,
  data,
})

export const reqSetIsShowIntroFutureBtn = (data) => (dispatch) => {
  return dispatch(actSetIsShowIntroFutureBtn(data));
};


export const reqSetIsCollapsedCategoryDetail = (data) => (dispatch) => {
  return dispatch(actSetIsCollapsedCategoryDetail(data));
};

export const reqSetIsShowVirtualTour = (data) => (dispatch) => {
  return dispatch(actSetIsShowVirtualTour(data));
};

export const reqSetIsShowCategoryDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowCategoryDetail(data));
};

export const reqSetActiveCategory = (data) => (dispatch) => {
  return dispatch(actSetActiveCategory(data));
};

export const reqSetIsShowSidebar = (data) => (dispatch) => {
  return dispatch(actSetIsShowSidebar(data));
};

export const reqIsLoadedIntroVideo = (data) => (dispatch) => {
  return dispatch(acetSetIsLoadedIntroVideo(data));
};

export const reqSetIsShowGuidePopup = (data) => (dispatch) => {
  return dispatch(actSetIsShowGuidePopup(data));
};

export const reqSetPage = (data) => (dispatch) => {
  return dispatch(actSetPage(data));
};

export const reqSetIsShowImmerse = (data) => (dispatch) => {
  return dispatch(actSetIsShowImmerse(data));
};

export const reqSetIsExpandNav = (data) => (dispatch) => {
  return dispatch(actSetIsExpandNav(data));
};

export const reqSetIsShowFuture = (data) => (dispatch) => {
  return dispatch(actSetIsShowFuture(data));
};

export const reqSetActivePatourId = (data) => (dispatch) => {
  return dispatch(actSetActivePatourId(data));
};

export const reqSetIsShowExploreModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowExploreModal(data));
};

export const reqSetIsTransparent = (data) => (dispatch) => {
  return dispatch(actSetIsTransparent(data));
};

export const reqSetActiveGalleryId = (data) => (dispatch) => {
  return dispatch(actSetActiveGalleryId(data));
};

export const reqSetActiveAreaId = (data) => (dispatch) => {
  return dispatch(actSetActiveAreaId(data));
};

export const reqSetIsShowSubPrecinctDetail = (data) => async (dispatch) => {
  return dispatch(actSetIsShowSubPrecinctDetail(data));
};

export const reqSetActiveDistrictYear = (data) => (dispatch) => {
  return dispatch(actActiveDistrictYear(data));
};

export const reqSetIsShowLoginModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowLoginModal(data));
};

export const reqSetTransportOptions = (data) => (dispatch) => {
  return transportOptionApi
    .getTransportOptions()
    .then((data) => {
      dispatch(actSetTransportOptions(data));
    })
};

export const reqSetTransportOptionDistricts = (data) => (dispatch) => {
  return transportOptionApi
    .getTransportOptionDistricts()
    .then((data) => {
      dispatch(actSetTransportOptionDistricts(data));
    })
};

export const reqSetSubPrecincts = (data) => (dispatch) => {
  return subPrecinctApi
    .getSubPrecincts()
    .then((data) => {
      dispatch(actSetSubPrecincts(data));
    })
};

export const reqGetListVirtualModals = (data) => (dispatch) => {
  return modal
    .getListModal({ 'type[equal]': 'virtual' })
    .then((data) => {
      dispatch(actSetListVirtualModals(data));
    })
};

export const reqSetActiveTransportOption = (data) => (dispatch) => {
  return dispatch(actSetActiveTransportOption(data));
};

export const reqSetActiveTransportOptionDistricts = (data) => (dispatch) => {
  return dispatch(actSetActiveTransportOptionDistricts(data));
};

export const reqSetIsShowEndGuideTenantSession = (data) => (dispatch) => {
  return dispatch(actSetIsShowEndGuideTenantSession(data));
}

export const reqSetActiveEndGuideTenantSessionId = (data) => (dispatch) => {
  return dispatch(actSetActiveEndGuideTenantSessionId(data));
}

export const reqSetIsShowHotspotModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowHotspotModal(data));
};

export const reqSetIsShowGalleryModal = (data) => (dispatch) => {
  return dispatch(actSetIsShowGalleryModal(data));
};

export const reqSetSelectedHotspot = (data) => (dispatch) => {
  return dispatch(actSetSelectedHotspot(data));
}
